<template>
  <b-row>
    <b-col class="nopadding" cols="12" xl="3">
      <b-card>
        <div slot="header">
          <strong># {{$t('common.new_company')}}</strong>
        </div>
        <div class="text-center">
          <img v-if="company.logo" :src="computed_logo(company.logo)"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <h6>{{$t('common.choose_another_image')}}</h6>
          <input type="file" class="text-center center-block file-upload"
          @change="getImage">
        </div>
        <br>
        <div class="row">
          <div class="col bg-secondary">
            <b-form-group>
              <label for="txt_company_name">{{$t('common.name')}}</label>
              <div v-if="$v.company.name.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.company.name.required">{{$t('common.name_required')}}</div>
              </div>
              <b-form-input type="text" id="txt_company_name" :placeholder="$t('common.placeholder_name')"
              v-model="company.name" maxlength="50" ref="txt_name" size="sm"
              v-on:input="$v.company.name.$touch"></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="txtdienthoai">{{$t('common.phone')}}</label>
              <div v-if="$v.company.phone.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.company.phone.required">{{$t('common.phone_required')}}</div>
                  <div class="text-danger" v-else-if="!$v.company.phone.numeric">{{$t('common.phone_number_only')}}</div>
                  <div class="text-danger" v-else-if="!$v.company.phone.minLength">{{$t('common.phone_ten_number')}}</div>
              </div>
              <b-form-input type="text" id="txtdienthoai" :placeholder="$t('common.placeholder_phone')"
              v-model="company.phone" maxlength="50" size="sm"
              v-on:input="$v.company.phone.$touch"></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="txtbusiness">{{$t('common.business')}}</label>
              <div v-if="$v.company.business.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.company.business.required">{{$t('common.business_required')}}</div>
              </div>
              <b-form-select id="ddlbusiness" v-model="company.business"
                v-on:input="$v.company.business.$touch" :disabled="company.id!=null" size="sm">
                  <option value="null" disabled>{{$t('common.select_business')}}</option>
                  <option v-for="d in ls_business" :value="d.code"
                      v-bind:key="d.code">{{d.name[$i18n.locale]}}</option>
              </b-form-select>
            </b-form-group>

            <b-form-group>
              <label for="txt_package">{{$t('common.package')}}</label>
              <div v-if="$v.company.package_id.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.company.package_id.required">{{$t('common.package_required')}}</div>
              </div>
              <b-form-select id="txt_package" v-model="company.package_id"
                v-on:input="$v.company.package_id.$touch" size="sm">
                  <option value="null" disabled>{{$t('common.select_package')}}</option>
                  <option v-for="d in ls_package_ddl" :value="d.id"
                      v-bind:key="d.code">{{JSON.parse(d.name)[$i18n.locale]}}</option>
              </b-form-select>
            </b-form-group>

            <b-form-group>
              <label for="txt_company_code">{{$t('common.code')}}</label>
              <div v-if="$v.company.code.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.company.code.required">{{$t('common.code_required')}}</div>
              </div>
              <b-form-input type="text" id="txt_company_code" :placeholder="$t('common.plcaholder_code')"
              v-model="company.code" maxlength="20" :disabled="company.id!=null" size="sm"
              v-on:input="$v.company.code.$touch"></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="chkcocon">{{$t('common.has_branch')}} </label>
              <b-form-checkbox switch size="lg" id="chkcocon"
              v-model="company.cocon"></b-form-checkbox>
            </b-form-group>
            <fieldset class="scheduler-border">
              <legend class="scheduler-border">{{$t('common.working_time')}}</legend>
              <b-form-group>
                <div class="row">
                  <div class="col-2 "><label for="txt_sang">{{$t('common.morning')}}</label></div>
                  <div class="col-5">
                    <label for="txt_sang_tu">{{$t('common.from')}}</label>
                    <div v-if="$v.company.sang_tu.$error" class="pull-right">
                      <div class="text-danger" v-if="!$v.company.sang_tu.required">{{$t('common.time_required')}}</div>
                    </div>
                    <b-form-input type="time" class="form-control" v-model="company.sang_tu" id="txt_sang_tu" size="sm"
                      maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
                  </div>
                  <div class="col-5">
                    <label for="txt_sang_den">{{$t('common.to')}}</label>
                    <div v-if="$v.company.sang_den.$error" class="pull-right">
                        <div class="text-danger" v-if="!$v.company.sang_den.required">{{$t('common.time_required')}}</div>
                    </div>
                    <b-form-input type="time" class="form-control" v-model="company.sang_den" id="txt_sang_den" size="sm"
                      maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2 "><label for="txt_chieu">{{$t('common.afternoon')}}</label></div>
                  <div class="col-5">
                    <label for="txt_chieu_tu">{{$t('common.from')}}</label>
                    <div v-if="$v.company.chieu_tu.$error" class="pull-right">
                      <div class="text-danger" v-if="!$v.company.chieu_tu.required">{{$t('common.time_required')}}</div>
                    </div>
                    <b-form-input type="time" class="form-control" v-model="company.chieu_tu" id="txt_chieu_tu" size="sm"
                      maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
                  </div>
                  <div class="col-5">
                    <label for="txt_chieu_den">{{$t('common.to')}}</label>
                    <div v-if="$v.company.chieu_den.$error" class="pull-right">
                        <div class="text-danger" v-if="!$v.company.chieu_den.required">{{$t('common.time_required')}}</div>
                    </div>
                    <b-form-input type="time" class="form-control" v-model="company.chieu_den" id="txt_chieu_den" size="sm"
                      maxLength="20" :placeholder="$t('common.placeholder_time')" min="01:00" max="24:00"></b-form-input>
                  </div>
                </div>
              </b-form-group>
            </fieldset>

            <b-form-group>
              <label for="txt_fax">{{$t('common.fax')}}</label>
              <b-form-input type="text" id="txt_fax" :placeholder="$t('common.placeholder_fax')"
              v-model="company.fax" maxlength="20" size="sm"></b-form-input>
            </b-form-group>

              <b-form-group>
              <label for="txt_tax">{{$t('common.tax')}}</label>
              <b-form-input type="text" id="txt_tax" :placeholder="$t('common.placeholder_tax')"
              v-model="company.tax" maxlength="20" size="sm"></b-form-input>
            </b-form-group>

            <b-form-group>
              <label for="txt_des">{{$t('common.introduction')}}</label>
              <b-form-textarea type="text" id="txt_des"
              v-model="company.des" maxlength="200" size="sm"
              :placeholder="$t('common.placeholder_introduction')"></b-form-textarea>
            </b-form-group>

            <b-form-group>
              <label for="txtdiachi">{{$t('common.address')}}</label>
              <b-form-textarea type="text" id="txtdiachi"
              v-model="company.address" maxlength="200" size="sm"
              :placeholder="this.$t('common.placeholder_address')"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row pull-right mt-2">
          <b-button class="mt-1" variant="outline-success" @click.prevent="clear()">
            <i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</b-button>
          <b-button class="ml-2 mt-1" variant="outline-primary" @click.prevent="submit()"><span class="icon is-small">
            <i class="fa fa-check"></i> {{$t('common.button.save')}} </span></b-button>
        </div>
      </b-card>
    </b-col>
    <b-col class="nopadding" cols="12" xl="9">
      <transition name="slide">
        <b-card>
          <div slot="header"><strong><i class="fa fa-list" aria-hidden="true"></i> {{$t('common.company_list')}}</strong></div>
          <b-form-group label-for="table-style-variant" label-cols-lg="2">
            <div class="input-group">
              <input type="text" class="form-control" :placeholder="$t('common.search_text')"
              v-model="filter" maxlenght="50" @keyup.enter="apply_filter">
              <div class="input-group-append">
                  <button class="btn btn-outline-secondary" @click="apply_filter"
                  type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
              </div>
            </div>
          </b-form-group>
          <b-table striped hover responsive :tbody-tr-class="rowDeleted"
          :items="filteredAndSortedData" :fields="fields" :current-page="page"
          :per-page="0" @row-clicked="rowClicked">
            <template slot="top-row" slot-scope="{ fields }">
              <td v-for="field in fields" :key="field.key">
                <input v-model="filteredAndSortedData[field.key]" 
                v-if="field.key && field.label" :placeholder="field.label">
              </td>
            </template>
            <template slot="name" slot-scope="data">
              <img v-if="data.item.logo" :src="computed_logo(data.item.logo)"
              class="avatar img-circle img-thumbnail" alt="avatar">
              <br/>
              <div>{{data.item.name}}</div>
            </template>
            <template slot="status" slot-scope="data">
              <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge>
            </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
            <template slot="cocon" slot-scope="data">
              <div v-if="data.item.cocon">Có</div>
              <div v-else>Không</div>
            </template>
            <template slot="updated_at" slot-scope="data">
              <div v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</div>
              <div v-else></div>
            </template>

            <template slot="action" slot-scope="data">
              <b-button-group size="sm">
                <b-button variant="outline-danger"
                v-if="data.item.status>-1"
                  @click="confirmDelete(data.item)">
                  <i class="fa fa-trash"></i> {{$t('common.button.delete')}}
                </b-button>
                <b-button variant="outline-success" v-else
                  @click="recoverObj(data.item)">
                  <i class="fa fa-recycle"></i> {{$t('common.button.recover')}}
                </b-button>
              </b-button-group>
            </template>
          </b-table>
          <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count"
                        :page="page"
                        @change="change_page">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </transition>
    </b-col>
  </b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
  fieldset.scheduler-border {
    border: 1px groove #e4e7ea !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }

    legend.scheduler-border {
        font-size: 1.2em !important;
        font-weight: bold !important;
        text-align: left !important;
        width:auto;
        padding:0 10px;
        border-bottom:none;
    }
</style>
<script>
  import { validationMixin } from 'vuelidate'
  import { required, minLength,maxLength,numeric } from 'vuelidate/lib/validators'
  import { mapGetters, mapActions } from 'vuex'
  import Pager from '../Pager'
  import moment from 'moment'
  import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
  import { Role } from '@/helpers/role.js'
  import { uuid } from 'vue-uuid'

  export default {
    mixins: [validationMixin],
    components: {Pager},
    props: {
      hover: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
      return {
        ls_business:[],
        company:{
          id:null,
          business:null,
          user_create_id:null,
          user_update_id:null,
          logo:null,
          logo_url:null,
          des:null,
          sang_tu:'09:00',
          sang_den:'12:00',
          chieu_tu:'13:00',
          chieu_den:'18:00',
          giay_phep_hoat_dong:null,
          ma_so_ho_so:null,
          fax:null,
          tax:null,
          name:null,
          code:moment().valueOf().toString(),
          address:null,
          phone:null,
          cocon:false,
          discount_all:false,
          is_admin:false,
          package_id:null
        }
      }
    },

    validations: {
      company: {
        package_id:{
          required
        },
        chieu_tu:{
          required
        },
        chieu_den:{
          required
        },
        sang_tu:{
          required
        },
        sang_den:{
          required
        },
        business:{
          required
        },
        code: {
          required,
          maxLength:maxLength(20),
          async isMaUnique(value) {
            if (value==null) return true
            if(this.company && this.company.id){return true}

            const res = await this.checkCodeUnique(value)
            return Boolean(res.data)
          }
        },
        name: {
          required,
          maxLength:maxLength(50)
        },
        phone: {
          required,
          minLength: minLength(10),
          maxLength:maxLength(15),
          numeric,
          async isPhoneUnique(value) {
            if (value==null) return true
            if(this.company && this.company.id){
                return true
            }
            const res = await this.checkPhoneUnique(value)
            return Boolean(res.data)
          }
        }
      }
    },
    computed: {
      ...mapGetters('st_company', ['ls_company','page_count','page','filter','per_page']),
      fields(){
        let fields=[]

        let user=this.$store.state.st_authentication.user
        if(user.role==Role.Super_Admin){
          fields.push({key: 'name',label:this.$t('common.company'),sortable: true, variant:'info'})
          fields.push({key: 'business',label:this.$t('common.business'),sortable: true})
        }else if(user.role==Role.Admin){
          fields.push({key: 'branch',label:this.$t('common.branch'),sortable: true, variant:'info'})
        }

        fields.push({key: 'phone',label:this.$t('common.fullname'),sortable: true, variant:'primary'})
        fields.push({key: 'sang_tu',label:this.$t('common.morning')+"-"+this.$t('common.from'),sortable: true})
        fields.push({key: 'sang_den',label:this.$t('common.morning')+"-"+this.$t('common.to'),sortable: true})
        fields.push({key: 'chieu_tu',label:this.$t('common.afternoon')+"-"+this.$t('common.from'),sortable: true})
        fields.push({key: 'chieu_den',label:this.$t('common.afternoon')+"-"+this.$t('common.to'),sortable: true})

        if(user.role==Role.Super_Admin){
          fields.push({key: 'cocon',label:this.$t('common.branch'),sortable: true})
        }
        fields.push({key: 'address',label:this.$t('common.address'),sortable: true})
        fields.push({key: 'des',label:this.$t('common.introduction'),sortable: true})

        fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})
        fields.push({key: 'status',label:this.$t('common.status'),sortable: true})

        if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
          fields.push({key: 'action',label:'',sortable: false})
        }

        return fields
      },
      ls_package_ddl(){
        return this.$store.state.st_package.ls_package_ddl
      },
      business(){
        let user= this.$store.state.st_authentication.user
        return user&&user.company.business
      },
      user_login () {
        return this.$store.state.st_authentication.user
      },
      filter:{
        get: function(){
          return this.$store.state.st_company.filter
        },
        set: function(text){
          this.$store.commit('st_company/set_filter',text)
        }
      },
      filteredAndSortedData:{
        get: function(){
          debugger
          let vm=this
          let result = vm.$store.state.st_company.ls_company
          if (vm.filter) {
              result = result.filter(item =>vm.searchLike(item))
          }

          return result
        }
      }
    },
    mounted () {
      let vm=this
      vm.$refs.txt_name.$el.focus()
      vm.ls_business=vm.option_business
      vm.ge_ls_package_ddl()
      vm.get_ls_company()
    },
    methods: {
      rowDeleted(item, type){
        debugger
        if (!item || type !== 'row') return
        debugger
        if (item.status === -1) return 'table-danger'
      },
      ge_ls_package_ddl(){
        this.$store.dispatch('st_package/get_ls_package_ddl')
      },
      getImage:function(e){
        let app=this
        let image=e.target.files[0]
        let reader=new FileReader()
        reader.readAsDataURL(image)
        reader.onload=e=>{
          app.company.logo=e.target.result
        }
      },
      ...mapActions('st_company', ['get_ls_company','apply_filter','change_page']),
      rowClicked (item) {
        this.company=item
      },
      getRowCount (items) {
        return items.length
      },

      async checkPhoneUnique(phone){
        return await this.$store.dispatch(`st_company/phone_unique`,phone)
      },

      async checkCodeUnique(code){
        return await this.$store.dispatch(`st_company/code_unique`,code)
      },

      _validations () {
        return new Promise(resolve => {
          if (this.$v.$error || !this.$v.$pending) {
              return resolve()
          }
          let poll = setInterval(() => {
          if (!this.$v.$pending) {
              clearInterval(poll)
              resolve()
          }
          }, 200)
        })
      },

      async isValid () {
        this.$v.$reset()
        this.$v.$touch()
        await this._validations()
        return Promise.resolve(!this.$v.$error)
      },

      async submit(){
        const isValid = await this.isValid()
        debugger
        if (!isValid) {
          return
        }
        var app = this

        if(app.processing ===true) return

        app.processing = true

        if(app.company.id){
          app.company.user_update_id=app.user_login.id
          app.$store.dispatch('st_company/update',app.company)
          .then(function (resp) {
            app.get_ls_company()
            app.clear()
            app.processing = false
            app.$toastr.success(app.$t("common.updated_success"),app.$t("common.message"))
          })
          .catch(function (resp) {
            app.processing = false
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          })
        }else{
          app.company.user_create_id=app.user_login.id
          app.$store.dispatch('st_company/create',app.company)
          .then(function (resp) {
            app.get_ls_company()
            app.clear()
            app.processing = false
            app.$toastr.success(app.$t("common.created_success"),app.$t("common.message"))
          })
          .catch(function (resp) {
            app.processing = false
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          })
        }
      },

      async recoverObj(obj){
        debugger
        let app=this
        if(obj.status>=0) return
        let co=await this.$store.dispatch('st_company/recover_obj',obj.id)
        debugger
        if(co && co.data.ok){
            this.get_ls_company()
            app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },
      confirmDelete(obj) {
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
        .then(value => {
          if(value!=true) return
          this.deleteObj(obj)
        })
        .catch(err => {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      },
      async deleteObj(obj) {
        debugger
        let app=this
        if(obj.status<0) return
        if(obj.status==1){
          app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.company")}),app.$t("common.message"))
          return
        }
        debugger
        let co=await this.$store.dispatch('st_company/delete_obj',obj.id)
        if(co && co.data.ok){
          this.get_ls_company()
          app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
        }else {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },

      clear() {
        this.company={id:null,name:null,business:null,package_id:null,sang_tu:'09:00',sang_den:'12:00',chieu_tu:'13:00',chieu_den:'18:00',
        fax:null,tax:null,phone:null,des:null,address:null,user_create_id:null,user_update_id:null,is_admin:false,
        cocon:false,logo_url:null,logo:null,code:moment().valueOf().toString(),
          discount_all:false}
        this.$refs.txt_name.$el.focus()
        this.$v.$reset()
      },

      searchLike:function(item){
        return item.name.includes(this.filter)
        ||item.code.includes(this.filter)
        ||item.address&&item.address.includes(this.filter)
        ||item.phone.includes(this.filter)
        ||item.des&&item.des.includes(this.filter)
        ||item.business.includes(this.filter)
        ||item.sang_tu&&item.sang_tu.includes(this.filter)
        ||item.chieu_tu&&item.chieu_tu.includes(this.filter)
        ||item.sang_den&&item.sang_den.includes(this.filter)
        ||item.chieu_den&&item.chieu_den.includes(this.filter)
        ||item.created_at.includes(this.filter)
        ||item.updated_at&&item.updated_at.includes(this.filter)
      }
    }
  }
</script>
